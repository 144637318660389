import {
  DataStorageKey,
  ecomAppDefID,
  lightboxes,
  membersAppDefId,
  wishlistAppDefId,
  wishlistPageId,
} from '../constants';
import {Logger} from '@wix/bi-logger-ec-sf';
import {FetchError} from '@wix/wixstores-client-core/dist/src/http/FetchError';
import {BulkInstallResponse} from '../types';
import {errorReporter} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/errorReporter';
import {SUBSCRIPTION_APP_DEFINITION_ID} from '@wix/wixstores-client-core/dist/src/constants';
import {
  addApplications,
  isMembersAreaAppInstalled,
  isMembersAreaInstalled,
  MA_APP_IDS,
  registerMembersAreaApps,
  removeApplications,
} from '@wix/members-area-integration-kit';
import {DataStorage} from './DataStorage';
import {STORES_APP_DEF_ID} from '../../stores-editor-script/constants';

export class DependantApps {
  private readonly dataStorage: DataStorage;

  constructor(
    private readonly sdk: IEditorSdk,
    private readonly appToken: string,
    private readonly biLogger: Logger,
    private readonly instance: string,
    metaSiteId: string
  ) {
    this.dataStorage = new DataStorage(metaSiteId);
  }

  private getMembersApi() {
    return this.sdk.application.getPublicAPI(this.appToken, {appDefinitionId: membersAppDefId});
  }

  public isAppInstalled(appDefinitionId: string): Promise<boolean> {
    return this.sdk.tpa.isApplicationInstalled(this.appToken, {
      appDefinitionId,
    });
  }

  public async installNewStoresAppIfNeeded() {
    const appData = await this.sdk.document.tpa.app.getDataByAppDefId('', STORES_APP_DEF_ID);
    if (appData) {
      return Promise.resolve();
    }
    return this.sdk.application.install('', {
      appDefinitionId: STORES_APP_DEF_ID,
      originInfo: {'': ''},
    });
  }

  public addCheckoutIfNeeded() {
    return this.sdk.tpa.isAppSectionInstalled(this.appToken, {sectionId: 'checkout'}).then((isInstalled) => {
      if (!isInstalled) {
        return this.sdk.tpa.add.component(this.appToken, {
          appDefinitionId: ecomAppDefID,
          componentType: 'PAGE',
          page: {
            pageId: 'checkout',
            shouldNavigate: false,
          },
        });
      }
    });
  }

  public installWishlistTPA(): Promise<void> {
    return fetch('/_api/site-apps/v1/site-apps/bulk-install', {
      method: 'post',
      body: JSON.stringify({
        apps: [{id: wishlistAppDefId}],
      }),
      headers: {
        Authorization: this.instance,
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.biLogger.wishlistInstallationStage({stage: `requestId${response.headers.get('x-wix-request-id')}`});
          console.error(response);
          errorReporter(new FetchError('bulk install failed', {response}));
          throw new FetchError('bulk install failed', {response});
        }
        return response.json();
      })
      .then((data: BulkInstallResponse) => {
        if (!data.installedApps[wishlistAppDefId].instanceId) {
          throw new Error('bulk install failed - no instanceId was returned');
        }
      });
  }

  public async tryInstallWishlist(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.biLogger.wishlistInstallationStage({stage: 'tryInstallWishlist-init'});
    await this.installWishlistTPA();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.biLogger.wishlistInstallationStage({stage: 'tryInstallWishlist-after-create-installWishlistTPAPromise'});
    await addApplications([MA_APP_IDS.MY_WISHLIST]);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.biLogger.wishlistInstallationStage({
      stage: 'tryInstallWishlist-after-create-installWishlistSectionInMembersPromise',
    });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.biLogger.wishlistInstallationStage({stage: 'tryInstallWishlist-after-promiseAll'});
  }

  public async installWishlistPageInMembersArea(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.biLogger.wishlistInstallationStage({stage: 'installWishlistPageInMembersArea-init'});
    const [isMembersInstalled, isMyWishlistInstalled] = await Promise.all([
      isMembersAreaInstalled(),
      isMembersAreaAppInstalled(MA_APP_IDS.MY_WISHLIST),
    ]);
    if (isMembersInstalled && !isMyWishlistInstalled) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.biLogger.wishlistInstallationStage({stage: 'installWishlistPageInMembersArea-inside-if'});
      const installWishlistTpaPromise = this.installWishlistTPA();

      const installWishlistSectionInMembersPromise = addApplications([MA_APP_IDS.MY_WISHLIST]);
      await Promise.all([installWishlistSectionInMembersPromise, installWishlistTpaPromise]);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.biLogger.wishlistInstallationStage({stage: 'installWishlistPageInMembersArea-after-promise-all'});
      await this.sdk.document.save().catch(() => {
        //
      });
    }
  }

  public async uninstallWishlistPageInMembersArea(): Promise<void> {
    const [isMembersInstalled, isMyWishlistInstalled] = await Promise.all([
      isMembersAreaInstalled(),
      isMembersAreaAppInstalled(MA_APP_IDS.MY_WISHLIST),
    ]);
    if (isMembersInstalled && isMyWishlistInstalled) {
      await removeApplications([MA_APP_IDS.MY_WISHLIST]);
      await this.sdk.document.save().catch(() => {
        //
      });
    }
  }

  public getWishlistPageRef(): Promise<any> {
    return this.getMembersApi().getMembersPageRef({appDefinitionId: ecomAppDefID, appPageId: wishlistPageId});
  }

  public async addLighboxes(applicationId: number) {
    const popupPages = await this.sdk.pages.popupPages.getApplicationPopups(ecomAppDefID);

    for (const lightbox of lightboxes) {
      const pageExists = !!popupPages.find((page) => page.tpaPageId === lightbox.tpaPageId);
      if (pageExists) {
        continue;
      }

      const componentDefinition = {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPASectionSkin',
        layout: {
          width: 980,
          height: 693,
          x: 0,
          y: 40,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAMultiSection',
        data: {
          type: 'TPAMultiSection',
          applicationId: applicationId.toString(),
          appDefinitionId: ecomAppDefID,
          widgetId: lightbox.widgetId,
        },
      };

      await this.sdk.document.pages.popupPages.add(lightbox.tpaPageId, {
        title: lightbox.title,
        definition: {
          data: {
            managingAppDefId: ecomAppDefID,
            tpaPageId: lightbox.tpaPageId,
            pageBackgrounds: {
              desktop: {
                custom: true,
                ref: {
                  type: 'BackgroundMedia',
                  color: '#A5C8DE',
                  colorOpacity: 0.75,
                  alignType: 'top',
                  fittingType: 'fill',
                  scrollType: 'fixed',
                },
                isPreset: true,
              },
            },
          },
          components: [
            {
              type: 'Container',
              components: [componentDefinition],
              skin: 'wysiwyg.viewer.skins.area.RectangleArea',
              layout: {
                width: 980,
                height: 693,
                x: 0,
                y: 40,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false,
                docked: {
                  hCenter: {px: 0},
                  vCenter: {px: 0},
                },
              },
              componentType: 'wysiwyg.viewer.components.PopupContainer',
              props: {
                type: 'PopupContainerProperties',
              },
            },
          ],
        },
        shouldNavigateToPage: false,
      });
    }
  }

  public async installMySubscriptionsPageInMembersAreaIfNeeded(options?: {biData?: {origin?: string}}) {
    const isSubscriptionsAppInstalled = await this.isAppInstalled(SUBSCRIPTION_APP_DEFINITION_ID);
    if (!isSubscriptionsAppInstalled) {
      return;
    }

    await registerMembersAreaApps(
      [MA_APP_IDS.MY_SUBSCRIPTIONS],
      [
        {
          id: MA_APP_IDS.MY_SUBSCRIPTIONS,
          options: {
            shouldInstallInitially: false,
          },
        },
      ]
    );

    const membersAreaInstalled = await isMembersAreaInstalled();
    if (membersAreaInstalled) {
      const mySubscriptionsAlreadyInstalled = await this.dataStorage.getData(
        DataStorageKey.MySubscriptionsAlreadyInstalled
      );

      if (mySubscriptionsAlreadyInstalled) {
        return;
      }

      await addApplications([MA_APP_IDS.MY_SUBSCRIPTIONS], options);
      await this.dataStorage.setData(DataStorageKey.MySubscriptionsAlreadyInstalled, true);
    }
  }
}
